import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import Templates from './Templates';
import Conditions from './Conditions';
import UsersManagement from './UsersManagement';
import Statistics from './Statistics';
import Logs from './Logs';
import Apps from './Apps';
import '../styles/Dashboard.css';
import TestPush from './TestPush';
import axios from 'axios';
import PushPieChart from './PushPieChart'; 


const Dashboard = ({ role, username }) => {
  const [panelCount, setPanelCount] = useState(2);
  const [conditions, setConditions] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [statistics, setStatistics] = useState([]);
  const location = useLocation();
  const team = localStorage.getItem('team');
  const [searchTerm, setSearchTerm] = useState('');
 // const role = localStorage.getItem('role');

  useEffect(() => {
    // Загружаем список условий
    const fetchConditions = async () => {
      try {
        const response = await axios.post('/api/get-conditions',{team:team, role: role});
        console.log()
        setConditions(response.data);
      } catch (error) {
        console.error('Ошибка при получении условий:', error);
      }
    };
    // Загружаем все данные статистики
    const fetchStatistics = async () => {
      try {
        const response = await axios.post('/api/statistics',{appName:"",geo:"",timeStart:"",timeEnd:"",team:team, role: role});
        setStatistics(response.data);
      } catch (error) {
        console.error('Ошибка при получении статистики:', error);
      }
    };

    // Чтение сохранённых данных из localStorage при загрузке
    const savedSelectedConditions = JSON.parse(localStorage.getItem('selectedConditions')) || {};
    setSelectedConditions(savedSelectedConditions);

    fetchConditions();
    fetchStatistics();
  }, []);
   console.log(role)
  // Сохраняем выбранные условия в localStorage при каждом изменении
  useEffect(() => {
    localStorage.setItem('selectedConditions', JSON.stringify(selectedConditions));
  }, [selectedConditions]);

  const handlePanelCountChange = (event) => {
    setPanelCount(Number(event.target.value));
  };

  const filteredConditions = conditions.filter(condition =>
    condition.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleConditionChange = (index, event) => {
    const selectedConditionId = event.target.value;
    setSelectedConditions(prev => ({ ...prev, [index]: selectedConditionId }));
  };

  const getGridTemplateColumns = () => {
    switch(panelCount) {
      case 2:
      case 4:
        return 'repeat(2, 1fr)';
      case 6:
        return 'repeat(3, 1fr)';
      case 8:
        return 'repeat(4, 1fr)';
      default:
        return 'repeat(2, 1fr)';
    }
  };

  const panels = Array.from({ length: panelCount }, (_, index) => {
    const selectedConditionId = selectedConditions[index];
    const conditionName = conditions.find(cond => cond._id === selectedConditionId)?.name;
    const filteredStatistics = statistics.find(stat => stat.conditionName === conditionName);  // Находим статистику по названию условия

    return (
      <div key={index} className="info-panel">
        {/* Шапка с выбором условия */}
        <div className="panel-header">
          <label className="statP" htmlFor={`condition-select-${index}`}>Выбор статистики:</label>
          <input
          type="text"
          placeholder="Поиск..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <select
          id={`condition-select-${index}`}
          value={selectedConditions[index] || ''}
          onChange={(event) => handleConditionChange(index, event)}
        >
          <option value="">Выберите условие</option>
          {filteredConditions.map(condition => (
            <option key={condition._id} value={condition._id}>
              {condition.name}
            </option>
          ))}
        </select>
        </div>

        {/* Тело панели с диаграммой */}
        <div className="panel-body">
          {filteredStatistics ? (
            <PushPieChart pushCount={filteredStatistics.pushCount || 0} />  // Передаем количество пушей в диаграмму
          ) : (
            <p>Нет данных для диаграммы</p>
          )}
        </div>

        {/* Футер с отображением статистики */}
        <div className="panel-footer">
          {filteredStatistics ? (
            <div>
              <p className = "statP" >Количество пушей: {filteredStatistics.pushCount || 'Нет данных'}</p>
              <p className = "statP" >Дата отправки: {filteredStatistics.sentAt}</p>
            </div>
          ) : (
            <p>Нет статистики для выбранного условия</p>
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="dashboard-container">
      <Sidebar role={role} username={username} />
      <div className="main-content">
        <Routes>
          <Route 
            path="main" 
            element={
              <>
                <div className="panel-controls">
                  <label htmlFor="panel-count">Выбор кол-ва дэшбордов:</label>
                  <select id="panel-count" value={panelCount} onChange={handlePanelCountChange}>
                    <option value={2}>2</option>
                    <option value={4}>4</option>
                    <option value={6}>6</option>
                    <option value={8}>8</option>
                  </select>
                </div>
                <div className="panels-container" style={{ gridTemplateColumns: getGridTemplateColumns() }}>
                  {panels}
                </div>
                
              </>
            } 
          />
          {role === 'editor' || role === 'admin' ? (
            <>
              <Route path="templates" element={<Templates />} />
              <Route path="conditions" element={<Conditions />} />
            </>
          ) : null}
          <Route path="statistics" element={<Statistics />} />
          {role === 'admin' ? (
            <>
              <Route path="users" element={<UsersManagement />} />
              <Route path="logs" element={<Logs />} />
              <Route path="apps" element={<Apps />} />
              <Route path="test" element={<TestPush />} />
            </>
          ) : null}
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
