import React, { useRef, useEffect } from 'react';
import { Chart } from 'chart.js/auto';

const PushPieChart = ({ pushCount }) => {
  const chartRef = useRef(null);
  const data = {
    labels: ['Pushes'],
    datasets: [{
      label: 'Количество пушей',
      data: [pushCount],
      backgroundColor: ['#36a2eb'],
      hoverBackgroundColor: ['#36a2eb']
    }]
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false // Отключаем тултип для простоты
          },
          legend: {
            display: false, // Скрываем легенду
          }
        },
        elements: {
          center: {
            text: `${pushCount}`,
            color: '#36a2eb', // Цвет текста
            fontStyle: 'Arial', // Шрифт текста
            sidePadding: 20 // Отступы текста
          }
        }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [pushCount]);

  return (
    <div style={{ position: 'relative', width: '200px', height: '200px' }}>
      <canvas ref={chartRef} />
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#36a2eb'
      }}>
        {pushCount}
      </div>
    </div>
  );
};

export default PushPieChart;
