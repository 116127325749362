import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Statistics = () => {
  const [statistics, setStatistics] = useState([]);
  const [searchStatTerm, setSearchStatTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const itemsPerPage = 10;
  const [appName, setAppName] = useState('');
  const [geo, setGeo] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const team = localStorage.getItem('team');
  const role = localStorage.getItem('role');
  console.log(team)
  //const [selectedName, setSelectedName] = useState(null);

  useEffect(() => {
    // Запрос к API для получения статистики
    const fetchStatistics = async () => {
      try {
        const response = await axios.post('/api/statistics',{appName:"",geo:"",timeStart:"",timeEnd:"",team:team, role:role});
        setStatistics(response.data);
      } catch (error) {
        console.error('Ошибка при получении статистики:', error);
      }
    };


    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      const response = await axios.get('/api/statistics');
      setStatistics(response.data);
    } catch (error) {
      console.error('Ошибка при получении статистики:', error);
    }
  };
   const filteredStat = statistics
      .filter(statistics => statistics.conditionName.toLowerCase().includes(searchStatTerm.toLowerCase()))
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const totalPages = Math.ceil(statistics.length / itemsPerPage);

    const changePage = (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {

        const response = await axios.post('/api/statistics', { appName, geo, timeEnd ,timeStart,team, role });

        setAppName(appName);
        setGeo(geo);
        setTimeEnd(timeEnd)
        setTimeStart(timeStart)
        setStatistics(response.data)
        setIsFormVisible(false);
      } catch (error) {
        console.log('Сохранение данных:', {  appName, geo, timeEnd ,timeStart });
        console.error('Ошибка при сохранении группы шаблонов', error);
      }
    };

    const handleCancel = async (e) => {
      e.preventDefault();
      try {

        fetchStatistics();
        setAppName('');
        setGeo('');
        setTimeEnd('')
        setTimeStart('')
      } catch (error) {
        console.log('Сохранение данных:', {  appName, geo, timeEnd ,timeStart });
        console.error('Ошибка при сохранении группы шаблонов', error);
      }
    };    
      

    
  return (
    <div>
      <h2>Статистика</h2> 
      {/* Поле поиска по названию условия */}
      <input
        type="text"
        placeholder="Поиск по названию условия"
        value={searchStatTerm}
        onChange={(e) => setSearchStatTerm(e.target.value)}
        className="search-input"
      />
      <button onClick={() => setIsFormVisible(!isFormVisible)}>
        {isFormVisible ? 'Свернуть форму' : 'Фильтр'}
      </button>
      {isFormVisible && (
          <form onSubmit={handleSubmit}>
          <div>
            <label>Название приложения:</label>
            <input
              type="text"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
              defaultValue={null}
            />
          </div>

          <div>
            <label>Гео:</label>
            <input
              type="text"
              value={geo}
              defaultValue={null}
              onChange={(e) => setGeo(e.target.value)}
            />
          </div>

          <div>
            <label>Дата начала:</label>
            <input
              type="text"
              value={timeStart}
              defaultValue={null}
              onChange={(e) => setTimeStart(e.target.value)}
            />
          </div>

          <div>
            <label>Дата конца:</label>
            <input
              type="text"
              value={timeEnd}
              defaultValue={null}
              onChange={(e) => setTimeEnd(e.target.value)}
            />
          </div>
          <button type="submit">Сохранить</button>
          <button type="cancel" onClick={handleCancel}>Сбросить фильтр</button>
          </form>
        )}
      {statistics.length > 0 ? (
        <div>
            <ul className="stat-list">
            {filteredStat.map((group,index) => (
            <li key={group._id}>
              <div key={index} style={{ width: "100%" , textAlign: 'left' }}>
              <button
                onClick={() => {
                  const panel = document.getElementById(`panel-${index}`);
                  panel.style.display = panel.style.display === 'none' ? 'block' : 'none';
                }}
                style={{ width: "100%" , textAlign: 'left', margin: "0" }}
              >
                {`Условие: ${group.conditionName}`} {/* Отображаем название условия */}
              </button>
              <div id={`panel-${index}`} style={{ display: 'none', marginTop: '10px' }}>
                <p>Название группы шаблонов: {group.templateName}</p> {/* Отображаем название шаблона */}
                <p>Количество пушей: {group.pushCount}</p>
                <p>Дата отправки: { group.sentAt}</p>
                <p>Кол-во рег: { group.regCount}</p>
                <p>Кол-во депов: { group.depCount}</p>
              </div>
            </div>
          </li>
                 
        ))}
      </ul>
      <div className="pagination">
        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>Назад</button>
        <span>Страница {currentPage} из {totalPages}</span>
        <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>Вперед</button>
      </div>

        </div>
      ) : (
        <p>Нет данных для отображения.</p>
      )}
    </div>
  );
};

export default Statistics;
