import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import '../styles/Conditions.css';

const Conditions = () => {
  const authContext = useContext(AuthContext);
  const token = authContext ? authContext.token : null;

  const [conditionName, setConditionName] = useState('');
  const [type, setType] = useState('');
  const [cronExpression, setCronExpression] = useState('');
  const [trackingEventInitial, setTrackingEventInitial] = useState('');
  const [trackingEventFulfilled, setTrackingEventFulfilled] = useState('');
  const [delay, setDelay] = useState(0);
  const [os, setOs] = useState('');
  const [appId, setAppId] = useState('');
  const [offer, setOffer] = useState('');
  const [countries, setCountries] = useState('');
  const [flow, setFlow] = useState('');
  const [conditions, setConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [apps, setApps] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchConditionTerm, setSearchConditionTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchApps = useCallback(async () => {
    try {
      const res = await axios.get('/api/apps');
      setApps(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке списка приложений', error);
    }
  }, []);

  const fetchConditions = useCallback(async () => {
    try {
      const res = await axios.get('/api/conditions');
      setConditions(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке условий', error);
    }
  }, []);

  useEffect(() => {
    fetchApps();
    fetchConditions();
  }, [fetchApps, fetchConditions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const conditionData = {
      name: conditionName,
      type,
      cronExpression,
      trackingEventInitial,
      trackingEventFulfilled,
      delay,
      os,
      appId,
      offer,
      countries,
      flow,
    };
    try {
      if (selectedCondition) {
        await axios.put(`/api/conditions/${selectedCondition._id}`, conditionData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await axios.post('/api/conditions', conditionData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      resetForm();
      fetchConditions();
    } catch (error) {
      console.error('Ошибка при сохранении условия', error);
    }
  };

  const resetForm = () => {
    setConditionName('');
    setType('');
    setCronExpression('');
    setTrackingEventInitial('');
    setTrackingEventFulfilled('');
    setDelay(0);
    setOs('');
    setAppId('');
    setOffer('');
    setCountries('');
    setFlow('');
    setSelectedCondition(null);
  };

  const handleEditCondition = (condition) => {
    setConditionName(condition.name);
    setType(condition.type);
    setCronExpression(condition.cronExpression);
    setTrackingEventInitial(condition.trackingEventInitial);
    setTrackingEventFulfilled(condition.trackingEventFulfilled);
    setDelay(condition.delay);
    setOs(condition.os);
    setAppId(condition.appId);
    setOffer(condition.offer);
    setCountries(condition.countries);
    setFlow(condition.flow);
    setSelectedCondition(condition);
    setIsFormVisible(true);
  };

  const handleDeleteCondition = async (conditionId) => {
    try {
      await axios.delete(`/api/conditions/${conditionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchConditions();
    } catch (error) {
      console.error('Ошибка при удалении условия', error);
    }
  };

  const filteredConditions = conditions
    .filter(condition => condition.name.toLowerCase().includes(searchConditionTerm.toLowerCase()))
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(conditions.length / itemsPerPage);

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="conditions-container">
      <h2>Условия</h2>

      {/* Поле поиска по названию условия */}
      <input
        type="text"
        placeholder="Поиск по названию условия"
        value={searchConditionTerm}
        onChange={(e) => setSearchConditionTerm(e.target.value)}
        className="search-input"
      />

      <button onClick={() => setIsFormVisible(!isFormVisible)}>
        {isFormVisible ? 'Свернуть форму' : 'Создать условие'}
      </button>

      {isFormVisible && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Название условия:</label>
            <input
              type="text"
              value={conditionName}
              onChange={(e) => setConditionName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Тип пуша:</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value=""></option>
              <option value="regular">Regular</option>
              <option value="event">Event</option>
            </select>
          </div>
          <div className="form-group">
            <label>Сron:</label>
            <input
              type="text"
              value={cronExpression}
              onChange={(e) => setCronExpression(e.target.value)}
              placeholder="[Регулярный] Шаблон для регулярных пушей"
            />
          </div>
          <div className="form-group">
            <label>Инициирующее событие:</label>
            <select
              value={trackingEventInitial}
              onChange={(e) => setTrackingEventInitial(e.target.value)}
            >
              <option value=""></option>
              <option value="af_purchase">af_purchase</option>
              <option value="firstLogin">firstLogin</option>
              <option value="login">login</option>
              <option value="pushClick">pushClick</option>
              <option value="pushReceive">pushReceive</option>
              <option value="registration">registration</option>
            </select>
          </div>
          <div className="form-group">
            <label>Завершающее событие:</label>
            <select
              value={trackingEventFulfilled}
              onChange={(e) => setTrackingEventFulfilled(e.target.value)}
            >
              <option value=""></option>
              <option value="af_purchase">af_purchase</option>
              <option value="firstLogin">firstLogin</option>
              <option value="login">login</option>
              <option value="pushClick">pushClick</option>
              <option value="pushReceive">pushReceive</option>
              <option value="registration">registration</option>
            </select>
            <small>(не обязательно) Событие после которого необходимо отменить отправку пуша</small>
          </div>
          <div className="form-group">
            <label>Задержка (в секундах):</label>
            <input
              type="number"
              value={delay}
              onChange={(e) => setDelay(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>OS:</label>
            <select
              value={os}
              onChange={(e) => setOs(e.target.value)}
            >
              <option value=""></option>
              <option value="iOS">iOS</option>
              <option value="Android">Android</option>
              <option value="PWA">PWA</option>
              <option value="Telegram">Telegram</option>
            </select>
          </div>
          <div className="form-group">
            <label>Приложение:</label>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Поиск приложения"
            />
            <select
              value={appId}
              onChange={(e) => setAppId(e.target.value)}
            >
              <option value="">Выберите приложение</option>
              {apps.filter(app => app.name.toLowerCase().includes(searchTerm.toLowerCase())).map(app => (
                <option key={app._id} value={app._id}>{app.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Оффер:</label>
            <input
              type="text"
              value={offer}
              onChange={(e) => setOffer(e.target.value)}
              placeholder="Укажите оффер"
            />
          </div>
          <div className="form-group">
            <label>Страны:</label>
            <input
              type="text"
              value={countries}
              onChange={(e) => setCountries(e.target.value)}
              placeholder="Укажите страны"
            />
          </div>
          <div className="form-group">
            <label>Поток:</label>
            <input
              type="text"
              value={flow}
              onChange={(e) => setFlow(e.target.value)}
              placeholder="Укажите поток"
            />
          </div>
          
          <button type="submit">Сохранить</button>
          {selectedCondition && (
            <button type="button" onClick={resetForm}>Очистить форму</button>
          )}
        </form>
      )}

      <h3>Существующие условия</h3>
      <ul className="conditions-list">
        {filteredConditions.map(condition => (
          <li key={condition._id}>
            <span>{condition.name} (Приложение: {condition.appId.name}, OS: {condition.os}, Тип: {condition.type})</span>
            <div className="condition-actions">
              <button className = "condition-actions" onClick={() => handleEditCondition(condition)}>Редактировать</button>
              <button className = "condition-actions" onClick={() => handleDeleteCondition(condition._id)}>Удалить</button>
            </div>
          </li>
        ))}
      </ul>

      <div className="pagination">
        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>Назад</button>
        <span>Страница {currentPage} из {totalPages}</span>
        <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>Вперед</button>
      </div>
    </div>
  );
};

export default Conditions;

