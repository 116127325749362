import React from 'react';
import { Routes, Route } from 'react-router-dom';

const MainContent = () => {
  return (
    <div className="main-content">
      <Routes>
        <Route path="/dashboard/main" element={<div>Главная</div>} />
        <Route path="/dashboard/templates" element={<div>Шаблоны</div>} />
        <Route path="/dashboard/conditions" element={<div>Условия</div>} />
        <Route path="/dashboard/statistics" element={<div>Статистика</div>} />
        <Route path="/dashboard/users" element={<div>Управление пользователями</div>} />
        <Route path="/dashboard/logs" element={<div>Логи</div>} />
        <Route path="/dashboard/apps" element={<div>Добавление приложений</div>} />
        <Route path="/dashboard/test" element={<div>Тест пушей</div>} />
      </Routes>
    </div>
  );
};

export default MainContent;
