import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import Sidebar from '../components/Sidebar';
import {jwtDecode} from 'jwt-decode';

const Login = () => {
  const [usernameInput, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { username: usernameInput, password });
      localStorage.setItem('token', response.data.token); // Сохраняем токен
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      localStorage.setItem('username', usernameInput);
      localStorage.setItem('role', decodedToken.role);
      localStorage.setItem('team', decodedToken.team)
      console.log(decodedToken.team) // Сохраняем username
      navigate('/dashboard/main');
      Sidebar.forceUpdate();
      this.forceUpdate();
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Вход</h2>
        <div className="input-group">
          <input
            
            placeholder="Логин"
            value={usernameInput}
            onChange={(e) => setUsernameInput(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-button">Login</button>
        </div>
      </form>
    </div>
  );
};

export default Login;



